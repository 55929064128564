.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--navy);
  padding: 12px 25px;
  /* height: 56px; */
}

.header__left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__img img {
  height: 33px;
  width: auto;
  margin-right: 23px;
  cursor: pointer;
}

ul {
  display: flex;
  text-decoration: none;
  list-style: none;
  margin: 0;
  color: var(--light-gray);
  font-weight: 500;
  font-size: 16px;
}

ul li {
  margin-right: 20px;
  cursor: pointer;
}

.header__right {
  display: flex;
  justify-self: space-between;
  align-items: center;
  position: relative;
}

.header__right button {
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  font-family: "Helvetica Neue BD", Arial;
  color: white;
  padding: 7px 16px;
  border-radius: 4px;
  border: 1px solid var(--green);
  cursor: pointer;
}

.header__right button p {
  margin-left: 6px;
}

.header__user {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.header__user p {
  color: var(--light-gray);
  margin-left: 7px;
}

.header__support {
  margin-right: 30px;
  margin-left: 30px;
  cursor: pointer;
}

.header__support p {
  color: var(--light-gray);
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000000;
  opacity: 0.3;
  z-index: 2;
}

.header__account__menu {
  top: 70px;
  position: absolute;
  background-color: white;
  border-radius: 15px;
  max-width: 331px;
  z-index: 3;
}

.header__account__menu__profile {
  display: flex;
  align-items: center;
  padding: 35px 38px 20px 38px;

  border-bottom: 2px solid var(--gloss);
}

.header__account__menu__profile-left {
  margin-right: 15px;
}

.header__account__menu__profile-right-name {
  font-size: 18px;
  font-family: "Helvetica Neue BD";
  margin-bottom: 0;
}

.header__account__menu__profile-right-desc {
  font-size: 12px;
  color: var(--gray);
  margin-top: 0;
}

.header__account__menu__list ul {
  display: flex;
  flex-direction: column;
  color: var(--navy);
  margin-bottom: 10px;
  margin-top: 10px;
}

.header__account__menu__list ul li {
  /* margin-top: 14px;
  margin-bottom: 16px; */
  /* margin-left: 20px; */
  padding-top: 7px;
  padding-left: 14px;
  padding-right: 0;
  margin-left: 40px;

  display: block;
  height: 47px;
  width: 250px;
  display: flex;
  align-items: center;
}

.header__account__menu__list ul li:hover {
  background-color: var(--light-gray);
  border-radius: 4px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  background-color: var(--gloss);
  width: 256px;
  min-width: 256px;

  justify-content: flex-start;
  overflow-x: hidden;
  z-index: 1;
  height: calc(100vh - 80px);
}

.sidebar__items {
  margin-top: 26px;
  margin-left: 25px;
}

.sidebar__items ul {
  display: flex;
  flex-direction: column;
  color: var(--navy);
  font-size: 15px;
}

.sidebar__items p {
  font-size: 14px;
  margin-bottom: 16px;
  font-weight: 600;
  color: var(--gray);
}

.sidebar__items li {
  padding-top: 7px;
  padding-left: 31px;
  padding-right: 0;

  display: block;
  height: 30px;
  width: 185px;
  color: var(--navy);
}

.sidebar__items li:hover {
  background-color: var(--light-gray);
  border-radius: 4px;
}

.sidebar__items_li_selected {
  background-color: var(--light-gray);
  border-radius: 4px;
}

.login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin-top: 183px;
}

.login__logo {
  object-fit: contain;
  height: 61px;
}

.login__title {
  margin-top: 12px;
  font-size: 34px;
  font-family: "Helvetica Neue LT";
}

.login__container {
  display: flex;
  flex-direction: column;
}

.login__container input {
  height: 48px;
  width: 270px;
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  margin-top: 10px;
  padding-left: 15px;
  font-size: 16px;
  color: var(--gray);
}

.login__container input:first-child {
  margin-top: 0;
}

.login__container button {
  margin-top: 26px;
  background-color: var(--green);
  color: #ffff;
  height: 48px;
  border: 1px solid var(--green);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Helvetica Neue BD";
  cursor: pointer;
}

.login__options {
  margin-top: 29px;
  font-size: 13px;
  font-family: "Helvetica Neue MD";
  text-align: center;
}

.login__options p a {
  color: var(--navy);
}

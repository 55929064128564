.userRow {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  height: 88px;
  padding: 0px 24px;
  cursor: pointer;
}

.userRow__avatar {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
  flex-shrink: 0;
  font-size: 1.375rem;
  background-color: rgb(255, 190, 184);
  color: rgb(255, 190, 184);
}

.userRow__MainInfo {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  flex: 1 1 0%;
  margin-left: 20px;
  padding-right: 12px;
  min-width: 200px;
}

.userRow__Locations {
  flex: 1 1 0%;
  color: rgb(45, 47, 49);
  font-size: 14px;
  padding-right: 12px;
  min-width: 200px;
}

.userRow__RowActions {
  flex: 1 1 0%;
  display: flex;
}

.userRow-wrap:hover {
  background-color: var(--gloss);
}

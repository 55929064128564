.resetPassword {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 183px;
}

.resetPassword__title {
  margin-top: 12px;
  font-size: 22px;
  font-family: "Helvetica Neue MD";
  margin-bottom: 15px;
}

.resetPassword__desc {
  max-width: 280px;
  line-height: 24px;
  margin-bottom: 15px;
}

.resetPassword__container {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.resetPassword__container input {
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  margin-top: 10px;
  padding-left: 15px;
  font-size: 16px;
  color: var(--gray);
  height: 48px;
}

.resetPassword__container input:first-child {
  margin-top: 0;
}

.resetPassword__container button {
  margin-top: 26px;
  background-color: var(--red);
  color: #ffff;
  height: 48px;
  border: 1px solid var(--red);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Helvetica Neue BD";
  cursor: pointer;
}

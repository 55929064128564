.trialForm {
  width: 310px;

  z-index: 700;
}

.popTitle {
  color: var(--navy);
  font-size: 24px;
  margin-top: 30px;
  font-weight: bold;
  margin-bottom: 40px;
}

input {
  height: 40px;
  border: 1px solid var(--gray);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px 14px;
}

/* .nameWrap {
  display: flex;
} */

.label {
  color: var(--gray);
  margin: 10px 0;
  line-height: 17px;
  font-size: 14px;
}

.formErrors {
  color: var(--red);
  font-size: 12px;
  margin-top: 5px;
}

.popClose {
  float: right;
  background-image: url(../../images/pop-up-close.svg);
  height: 15px;
  width: 15px;
  background-repeat: no-repeat;
  content: "";
  cursor: pointer;
  margin-top: 21px;
}

.popup__actions {
  border-top: 2px solid var(--gloss);
  overflow: visible;
}

.popup__actions {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
  align-items: center;
}

.popup__actions-cancel {
  font-size: 14px;
  font-weight: 700;
  font-family: "Helvetica Neue BD", Arial;
  margin-right: 30px;
  cursor: pointer;
}

.popup_actions-save {
  background-color: var(--navy);
  color: white;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid var(--navy);
  border-radius: 2px;
  font-family: "Helvetica Neue BD", Arial;
  cursor: pointer;
}

@font-face {
  font-family: "Helvetica Neue BD";
  src: url("fonts/HelveticaNeueLTStd-Bd.eot");
  src: url("fonts/HelveticaNeueLTStd-Bd.eot?#iefix") format("embedded-opentype"),
    url("fonts/HelveticaNeueLTStd-Bd.woff2") format("woff2"),
    url("fonts/HelveticaNeueLTStd-Bd.woff") format("woff"),
    url("fonts/HelveticaNeueLTStd-Bd.ttf") format("truetype"),
    url("fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue LT";
  src: url("fonts/HelveticaNeueLTStd-Lt.eot");
  src: url("fonts/HelveticaNeueLTStd-Lt.eot?#iefix") format("embedded-opentype"),
    url("fonts/HelveticaNeueLTStd-Lt.woff2") format("woff2"),
    url("fonts/HelveticaNeueLTStd-Lt.woff") format("woff"),
    url("fonts/HelveticaNeueLTStd-Lt.ttf") format("truetype"),
    url("fonts/HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue MD";
  src: url("fonts/HelveticaNeueLTStd-Md.eot");
  src: url("fonts/HelveticaNeueLTStd-Md.eot?#iefix") format("embedded-opentype"),
    url("fonts/HelveticaNeueLTStd-Md.woff2") format("woff2"),
    url("fonts/HelveticaNeueLTStd-Md.woff") format("woff"),
    url("fonts/HelveticaNeueLTStd-Md.ttf") format("truetype"),
    url("fonts/HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("fonts/HelveticaNeueLTStd-Roman.eot");
  src: url("fonts/HelveticaNeueLTStd-Roman.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/HelveticaNeueLTStd-Roman.woff2") format("woff2"),
    url("fonts/HelveticaNeueLTStd-Roman.woff") format("woff"),
    url("fonts/HelveticaNeueLTStd-Roman.ttf") format("truetype"),
    url("fonts/HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  font-size: 1rem; /* 16px */
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif,
    Oswald;
  font-weight: 800;
  color: #314458;
}

* {
  margin: 0;
  padding: 0;
}

/*Setup - Font size */

h1 {
  /* 50px */
  font-family: "Helvetica Neue BD", Helvetica, Arial, "Lucida Grande",
    sans-serif;
  font-size: 3.125rem;
  line-height: 54px;
  margin-bottom: 38px;
}

h2 {
  /* 40px */
  font-family: "Helvetica Neue BD", Helvetica, Arial, "Lucida Grande",
    sans-serif;
  font-size: 2.5rem;
  line-height: 48px;
  margin: 0;
}

h3 {
  /*32px */
  font-family: "Helvetica Neue BD", Helvetica, Arial, "Lucida Grande",
    sans-serif;
  font-size: 2rem;
  margin: 0;
}

h4 {
  /* 22px */
  font-family: "Helvetica Neue BD", Helvetica, Arial, "Lucida Grande",
    sans-serif;
  font-size: 1.375rem;
  margin: 0;
}

p {
  font-weight: normal;
  margin: 0;
  line-height: 28px;
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
  color: var(--light-gray);
  margin: 0;
}

:root {
  --gloss: #ecf1f5;
  --green: #06a291;
  --light-gray: #cbd4dc;
  --navy: #314458;
  --blue: #509bf6;
  --gray: #6f7b84;
  --red: #f66366;
}

input {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.bold {
  font-weight: 700;
  color: var(--green);
  font-family: "Helvetica Neue BD", Arial;
  text-decoration: underline;
}

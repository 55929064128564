.settings__body {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
}

.settings-view-content-container {
  width: 100%;
  padding: 0px 48px 12px;
}

.locationwidget__pos {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.settings__header {
  margin-top: 25px;
}

.settings__header__title {
  font-size: 32px;
  margin-bottom: 30px;
}

.settings__header__options {
  display: flex;
}

.settings__header__options p {
  font-size: 16px;
  margin-right: 30px;
  font-weight: 700;
  cursor: pointer;
}

.options_hover {
  border-bottom: 3px solid var(--navy);
  padding-bottom: 12px;
  color: var(--navy);
}

.userTable {
  padding-top: 24px;
  min-width: 800px;
}

.userList__Headers {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  padding: 0px 24px;
  margin-bottom: 8px;
}

.UserList__HeaderItem {
  flex: 1 1 0%;
  font-size: 14px;
  font-weight: 600;
  min-width: 200px;
  color: rgb(45, 47, 49);
  margin-left: 20px;
}

.userRow {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  height: 88px;
  padding: 0px 24px;
  cursor: pointer;
}

.userRow__avatar {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
  flex-shrink: 0;
  font-size: 1.375rem;
  background-color: rgb(255, 190, 184);
  color: rgb(255, 190, 184);
}

.userRow__MainInfo {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  flex: 1 1 0%;
  margin-left: 20px;
  padding-right: 12px;
  min-width: 200px;
}

.userRow__Locations {
  flex: 1 1 0%;
  color: rgb(45, 47, 49);
  font-size: 14px;
  padding-right: 12px;
  min-width: 200px;
}

.userRow__RowActions {
  flex: 1 1 0%;
  display: flex;
}

.userRow-wrap:hover {
  background-color: var(--gloss);
}
